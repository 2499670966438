import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import "../index.css";

function HowToAddCollectionsForm() {
  const shopify_badge = (
    <Container
      component="a"
      href="https://apps.shopify.com/product-notifications-1?from=how-to-add-colls-form"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{ mt: 5, mb: 1, width: { xs: "50%", md: "22%" } }}
        component="img"
        alt="Find Vye on the Shopify App Store"
        src="/shopify_badge_black.svg"
      />
    </Container>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          pl: { xs: 10, md: 20 },
          pr: { xs: 10, md: 20 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          How to add a Vye collections form
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "100%" }}
            component="img"
            alt="A Vye collections form that can be added to a Shopify shop"
            src="/onboard2.png"
          />
        </Container>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography align="left" sx={{ mt: 3 }}>
            Follow these steps to add a collections form to your Shopify shop so
            that customers can subscribe to collections and receive email alerts
            when there are updates.
          </Typography>
          <ol>
            <li>
              Go to <strong>Settings</strong> of Vye, go to{" "}
              <strong>Collections Subscription</strong>, select the collections
              that you want your customers to subscribe to. You can select the
              checkbox{" "}
              <strong>
                Enable collection subscription on all collection pages
              </strong>{" "}
              to show the collections subscribe form on all collection pages.
              Save the changes.
            </li>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the theme in which you want to add the form, and then click{" "}
              <strong>Customize</strong> to open the theme editor.
            </li>
            <li>
              Select <strong>collections</strong> from the page selector
              drop-down menu at the top and then select{" "}
              <strong>Default collection</strong>.
            </li>
            <li>
              On the left side, select <strong>Add section</strong>. From the
              drop-down menu, in the <strong>Apps</strong> section, select{" "}
              <strong>Collection Subscribe Form</strong> or click the{" "}
              <strong>Search</strong> bar and enter <strong>Vye</strong> to find
              the form.
            </li>
            <li>
              Click <strong>Save</strong>. The form should appear on the page.
              Move it to where you want it on the page.{" "}
            </li>
            <li>
              Click on the form to customize it. To make the form cover the
              whole width of the page, click on <strong>Apps</strong> and remove
              the check from{" "}
              <strong>Make section margins the same as theme</strong>. Click{" "}
              <strong>Save</strong>.
            </li>
          </ol>
        </Container>
        {shopify_badge}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(HowToAddCollectionsForm);
