import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import "../index.css";

const allPosts = [
  {
    title: "How To Be A Real Lizard Once And For All",
    image: "/pnot.png",
    alt: "",
    tags: [],
  },
  {
    title: "How To Be A Real Bird Once And For All",
    image: "/pnot.png",
    alt: "",
    tags: [],
  },
  {
    title: "How To Be A Real Butterfly Once And For All",
    image: "/pnot.png",
    alt: "",
    tags: [],
  },
];

function PostTemplate() {
  const lastChanged = "Sep 1, 2023";

  function getRelatedPosts() {
    return true ? (
      <Box sx={{ mt: { xs: 1.5, md: 3 }, flexGrow: 1 }}>
        <Typography sx={{ mb: { xs: 1.5, md: 3 } }} variant="h6">
          Related posts:
        </Typography>
        <Grid
          container
          spacing={{ xs: 5, md: 7 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="left"
        >
          {Array.from(Array(allPosts.length)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Link href="contact" style={{ textDecoration: "none" }}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={allPosts[index].image}
                      alt={allPosts[index].alt}
                    />
                    <CardContent>
                      <Typography
                        align="center"
                        gutterBottom
                        variant="body2"
                        component="div"
                      >
                        {allPosts[index].title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    ) : null;
  }
  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 2.5, md: 5 },
          pl: { xs: 10, md: 25 },
          pr: { xs: 10, md: 25 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          How to be a lizard once and for all
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "100%" }}
            component="img"
            alt="Feature image of the new product notifications app"
            src="/pnot.png"
          />
        </Container>
        <Typography sx={{ mt: 3 }}>
          Vye sends email alerts to subscribers when you update products on your
          shop. Automated email alerts are triggered when new products are added
          or existing products are updated. There are three types of triggers.
          The first one is product tags. When you create products in Shopify,
          you can add tags to them. When you allow customers to subscribe to
          these tags, they will be alerted when a product with the tag they are
          interested in is added or updated. You can also have customers
          subscribe to all product updates in which case tag subscription is not
          needed.
          <br />
          <br />
          Vye sends email alerts to subscribers when you update products on your
          shop. Automated email alerts are triggered when new products are added
          or existing products are updated. There are three types of triggers.
          The first one is product tags. When you create products in Shopify,
          you can add tags to them. When you allow customers to subscribe to
          these tags, they will be alerted when a product with the tag they are
          interested in is added or updated. You can also have customers
          subscribe to all product updates in which case tag subscription is not
          needed. <br />
          <br />
          Vye sends email alerts to subscribers when you update products on your
          shop. Automated email alerts are triggered when new products are added
          or existing products are updated. There are three types of triggers.
          The first one is product tags. When you create products in Shopify,
          you can add tags to them. When you allow customers to subscribe to
          these tags, they will be alerted when a product with the tag they are
          interested in is added or updated. You can also have customers
          subscribe to all product updates in which case tag subscription is not
          needed. <br />
          <br />
          Vye sends email alerts to subscribers when you update products on your
          shop. Automated email alerts are triggered when new products are added
          or existing products are updated. There are three types of triggers.
          The first one is product tags. When you create products in Shopify,
          you can add tags to them. When you allow customers to subscribe to
          these tags, they will be alerted when a product with the tag they are
          interested in is added or updated. You can also have customers
          subscribe to all product updates in which case tag subscription is not
          needed.
        </Typography>
      </Container>
      <Container
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          mt: { xs: 0, md: 0 },
          mb: { xs: 2.5, md: 5 },
          pl: { xs: 10, md: 25 },
          pr: { xs: 10, md: 25 },
        }}
      >
        <Typography variant="caption">
          Last modified on {lastChanged}
        </Typography>
        {getRelatedPosts()}
      </Container>

      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(PostTemplate);
