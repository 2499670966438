import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Route, Routes } from "react-router-dom";

import { Post1 } from "./posts/Post1";
import { Post2 } from "./posts/Post2";
import { Post3 } from "./posts/Post3";
import { Post4 } from "./posts/Post4";
import { Post5 } from "./posts/Post5";

export const AllPosts = [Post1(), Post2(), Post3(), Post4(), Post5()];

export function makePost(p) {
  function createRelatedPosts(relatedPosts) {
    return relatedPosts && relatedPosts.length ? (
      <Box sx={{ mt: { xs: 1.5, md: 3 }, flexGrow: 1 }}>
        <Typography sx={{ mb: { xs: 1.5, md: 3 } }} variant="h6">
          Related posts:
        </Typography>
        <Grid
          container
          spacing={{ xs: 5, md: 7 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent="left"
        >
          {Array.from(Array(relatedPosts.length)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Link
                href={relatedPosts[index].path}
                style={{ textDecoration: "none" }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={relatedPosts[index].image}
                      alt={relatedPosts[index].alt}
                    />
                    <CardContent>
                      <Typography
                        align="center"
                        gutterBottom
                        variant="body2"
                        component="div"
                      >
                        {relatedPosts[index].title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    ) : null;
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 2.5, md: 5 },
          pl: { xs: 10, md: 25 },
          pr: { xs: 10, md: 25 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          {p.title}
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "100%" }}
            component="img"
            alt={p.alt}
            src={p.image}
          />
        </Container>
        <div>{p.content}</div>
      </Container>
      <Container
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          mt: { xs: 0, md: 0 },
          mb: { xs: 2.5, md: 5 },
          pl: { xs: 10, md: 25 },
          pr: { xs: 10, md: 25 },
        }}
      >
        <Typography variant="caption">
          Last modified on {p.lastModified}
        </Typography>
        {createRelatedPosts(getRelatedPosts(p))}
      </Container>

      <AppFooter />
    </React.Fragment>
  );
}

function getRelatedPosts(p) {
  if (p.tags && p.tags.length) {
    let relatedPosts = [];
    for (const a of AllPosts) {
      if (a.title !== p.title) {
        for (const t of a.tags) {
          if (p.tags.includes(t)) {
            relatedPosts.push(a);
            break;
          }
        }
        if (relatedPosts.length > 2) break;
      }
    }
    return relatedPosts;
  }
  return null;
}

export function getPostsRoutes() {
  let routes = [];
  for (const p of AllPosts) {
    routes.push(<Route path={"blog/" + p.path} element={p.element} />);
  }

  return <Routes>{routes}</Routes>;
}
