import Typography from "../../modules/components/Typography";
import { makePost } from "../Posts";
import withRoot from "../../modules/withRoot";

export function Post5() {
  return {
    title: "5. How To Be A Real Lizard Once And For All",
    image: "/pnot.png",
    alt: "",
    tags: ["tagB", "tagA"],
    path: "post5",
    element: <Post5Func />,
    lastModified: "Sep 5, 2023",
    content: (
      <Typography sx={{ mt: 3 }}>
        Vye sends email alerts to subscribers when you update products on your
        shop. Automated email alerts are triggered when new products are added
        or existing products are updated. There are three types of triggers. The
        first one is product tags. When you create products in Shopify, you can
        add tags to them. When you allow customers to subscribe to these tags,
        they will be alerted when a product with the tag they are interested in
        is added or updated. You can also have customers subscribe to all
        product updates in which case tag subscription is not needed.
        <br />
        <br />
        Vye sends email alerts to subscribers when you update products on your
        shop. Automated email alerts are triggered when new products are added
        or existing products are updated. There are three types of triggers. The
        first one is product tags. When you create products in Shopify, you can
        add tags to them. When you allow customers to subscribe to these tags,
        they will be alerted when a product with the tag they are interested in
        is added or updated. You can also have customers subscribe to all
        product updates in which case tag subscription is not needed. <br />
        <br />
        Vye sends email alerts to subscribers when you update products on your
        shop. Automated email alerts are triggered when new products are added
        or existing products are updated. There are three types of triggers. The
        first one is product tags. When you create products in Shopify, you can
        add tags to them. When you allow customers to subscribe to these tags,
        they will be alerted when a product with the tag they are interested in
        is added or updated. You can also have customers subscribe to all
        product updates in which case tag subscription is not needed. <br />
        <br />
        Vye sends email alerts to subscribers when you update products on your
        shop. Automated email alerts are triggered when new products are added
        or existing products are updated. There are three types of triggers. The
        first one is product tags. When you create products in Shopify, you can
        add tags to them. When you allow customers to subscribe to these tags,
        they will be alerted when a product with the tag they are interested in
        is added or updated. You can also have customers subscribe to all
        product updates in which case tag subscription is not needed.
      </Typography>
    ),
  };
}

function Post5Func() {
  return makePost(Post5());
}

export default withRoot(Post5Func);
