import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import "../index.css";
import { AllPosts } from "./Posts";
import { getPostsRoutes } from "./Posts";

function Blog() {
  console.log(getPostsRoutes());
  const postsPerPage = 3;
  const [displayedPosts, setDisplayedPosts] = useState(
    AllPosts.slice(0, postsPerPage)
  );

  function showPagination() {
    const count = Math.ceil(AllPosts.length / postsPerPage);
    return count > 1 ? (
      <Pagination
        sx={{
          mt: { xs: 5, md: 10 },
        }}
        count={count}
        color="secondary"
        onChange={(_, page) => {
          setDisplayedPosts(
            AllPosts.slice(
              (page - 1) * postsPerPage,
              (page - 1) * postsPerPage + postsPerPage
            )
          );
        }}
      />
    ) : null;
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 2.5, md: 5 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Blog
        </Typography>

        <Box sx={{ mt: { xs: 3.5, md: 7 }, flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 5, md: 7 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="center"
          >
            {Array.from(Array(displayedPosts.length)).map((_, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Link
                  href={"blog/" + displayedPosts[index].path}
                  style={{ textDecoration: "none" }}
                >
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={displayedPosts[index].image}
                        alt={displayedPosts[index].alt}
                      />
                      <CardContent>
                        <Typography
                          align="center"
                          gutterBottom
                          variant="body2"
                          component="div"
                        >
                          {displayedPosts[index].title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
        {showPagination()}
      </Container>

      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Blog);
